<template>
  <div class="activity">
    <div class="content" :class="{'turnContent':isTurn}">
      <div :class="{'turnContent': isTurn}">
        <div class="toRule flc hand" :class="{'turnBtn': isTurn}" @click="toRule">
          {{showRule ? '活动详情' : '规则'}}
        </div>

        <img v-show="!showRule"
             src="https://img.infox-med.com/webImg/infoPc/activity/invite12Content.jpg" alt=""
             class="bg_img">

        <iframe v-show="showRule" ref="ruleIframe" class="rule"
                src="https://apph5.infox-med.com/app-vue-h5pages/#/xy?componentName=Invite12Gz"
                frameborder="0"></iframe>
      </div>

    </div>

  </div>
</template>

<script>
export default {
  props: {},
  data () {
    return {
      showRule: false,
      isTurn: false
    }
  },
  methods: {
    toRule () {
      this.isTurn = !this.isTurn
      setTimeout(() => {
        this.showRule = !this.showRule
      }, 200)
    }
  },
  mounted () {
    const ruleContainer = this.$refs.ruleIframe
    ruleContainer.height = '1180px'
  },
  created () {

  }
}
</script>

<style lang="less" scoped>
.activity {
  background-color: #f3f6fa;
}
.content {
  position: relative;
  width: 700px;
  margin: 0 auto;
  padding: 20px 0;
  transform: rotateY(0deg);
  transition: all 0.4s;
  .toRule {
    position: absolute;
    top: 44px;
    right: 0;
    z-index: 109;
    // width: 48px;
    padding: 7px 15px;
    box-shadow: -2px 0px 3px 0px #ccc;
    // height: 26px;
    font-weight: 600;
    font-size: 12px;
    color: #000000;
    background: #ffffff;
    box-shadow: 0 0 2 0 rgba(78, 13, 122, 0.43);
    border-radius: 13px 0 0 13px;
  }
  .turnBtn {
    top: 24px;
  }
  .bg_img {
    width: 100%;
    border-radius: 8px;
  }
  .rule {
    width: 100%;
    border-radius: 8px;
  }
}

.turnContent {
  transform: rotateY(180deg);
}
</style>
